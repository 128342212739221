.Resolution {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.Resolution button{
  padding: 15px 30px;
  min-width: 250px;
}

.Resolution .Loading {
  transition: opacity 0.3s;
}

.Resolution .Loading.hide {
  opacity: 0;
}

.Resolution .Loading.show {
  opacity: 1;
}

.Resolution p.errorMessage {
  font-size: 2em;
  line-height: 120%;
  width: 100%;
  margin: 0px;
}

.Resolution p.errorMessage.hide {
  display: none;
  opacity: 0;
  
}

.Resolution p.errorMessage.show {
  display: block;
  opacity: 1;
}