section.cyberpunk.rules {
  padding: 60px 10px;
}

@media (min-width: 900px) {
  section.cyberpunk.rules > div {
    max-width: 1200px;
    margin: auto;
  }
}

#rules {
  padding-top: 50px;
}

.rules > div > div {
  margin-bottom: 10px;
}

.cyberpunk ul > li:nth-child(1):before,
.cyberpunk ul > li:nth-child(8):before {
  content: "\0072";
}
.cyberpunk ul > li:nth-child(2):before,
.cyberpunk ul > li:nth-child(7):before {
  content: "\0065";
}
.cyberpunk ul > li:nth-child(3):before {
  content: "\0073";
}
.cyberpunk ul > li:nth-child(4):before {
  content: "\006F";
}
.cyberpunk ul > li:nth-child(5):before {
  content: "\006C";
}
.cyberpunk ul > li:nth-child(6):before {
  content: "\0076";
}