html {
  scroll-behavior: smooth;
}



.misteries > div > div.pending {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  
}

.misteries > div > div.pending > div {
  max-width: calc(100% - 15px);
  padding-top: 50px;
}

@media (min-width: 700px) {
	.misteries > div > div.pending > div {
	  max-width: calc(50% - 15px);
	}
}

/* ------------------------------------------------------- */
.misteries > div > div.resolved {
  --gap: 15px;
  --border-height: 1px;
  --padding: 5px;
}


@media (min-width: 600px) {
	.misteries > div > div.resolved {
    --gap: 20px;
    --border-height: 3px;
    --padding: 10px;
	}
}

@media (min-width: 900px) {
	.misteries > div > div.resolved {
    --gap: 30px;
    --border-height: 5px;
    --padding: 20px;
	}
}

.misteries > div > div.resolved {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: var(--gap);
}

.misteries > div > div.resolved > div {
  width: calc(100% - var(--gap));
  box-sizing: border-box;
  border: var(--border-height) dashed var(--yellow-color);
  border-bottom: var(--border-height) dashed var(--purple-color); 
  padding: var(--padding);
  position: relative;
}

.misteries > div > div.resolved > div.children0 {
  border-bottom: var(--border-height) dashed var(--yellow-color); 
}

.misteries > div > div.resolved > div:after {
  content: "";
  position: absolute;
  width: 100%;
  height: var(--gap);
  background-color: var(--purple-color);
  left: 0px;
  bottom: calc( 0px - var(--gap) - var(--border-height));
  clip-path: polygon(calc(50% - 2px) 0%, calc(50% - 2px) 100%, calc(50% + 2px) 100%, calc(50% + 2px) 0%);
}

.misteries > div > div.resolved > div {
  width: calc(50% - var(--gap));
}

.misteries > div > div.resolved > div.resolved {
  border-color: var(--neon-color);
}

.misteries > div > div.resolved > div.resolved  * {
  color: var(--neon-color);
}

.misteries > div > div.resolved > div.resolved:after,
.misteries > div > div.resolved > div.resolved h2:before {
  background-color: var(--neon-color);
}

.misteries > div > div.resolved > div.pending h2:before {
  background-color: var(--purple-color);
}

.misteries > div > div.resolved > div.pending {
  border-color: var(--purple-color);
}

.misteries > div > div.resolved > div.pending * {
  color: var(--purple-color);
}

.misteries > div > div.resolved > .children2:after {
  clip-path: polygon(50% 0%, 25% 100%, 28% 100%, 50% 10%, 72% 100%, 75% 100%);
}

.misteries > div > div.resolved > .level1 {
  width: calc(100% - var(--gap));
}
.misteries > div > div.resolved > .level3 {
  width: calc(25% - var(--gap));  
}

.misteries > div > div.resolved > .children0:after {
  display: none;  
}

.misteries .mistery.current a {
  background-color: transparent;
  border: 1px solid var(--yellow-color);
  color: var(--yellow-color);
  display: inline-block;
  padding: 5px;
}

@media (max-width: 599px) {
  .misteries > div > div.resolved h2,
  .misteries .mistery.current a {
    font-size: 15px;
    line-height: 18px;
  }

  .misteries > div > div.resolved h2 {
    padding-bottom: 5px;
    margin: 0px;
  }

  .misteries > div > div.resolved h2:before {
    display: none;
  }

  .misteries > div > div.resolved > div.level3 {
    word-break: break-all;
  }
}

@media (max-width: 899px) {
  section.cyberpunk.misteries {
    padding: 60px 10px;
  }
}

@media (min-width: 900px) {
  section.misteries > div {
    max-width: 1200px;
    margin: auto;
  }

  .resolved > div.resolved:before,
  .misteries > div > div.resolved > div.pending:before {
    content: "RESOLVED";
    font-family: 'VT323';
    display: block;
    opacity: 0.7;
    font-size: 3em;
    color: var(--neon-color);
    position: absolute;
    transform: rotate(-15deg);
    transform-origin: center center;
    text-align: center;
    width: 100%;
    left: 0px;
    top: calc(50% - 0.5em);
    z-index: 10;
  }
  
  .misteries > div > div.resolved > div.pending:before {
    content: "NO ACCESS";
    color: var(--purple-color);
  }

  .misteries > div > div.resolved > div.resolved:before,
  .misteries > div > div.resolved > div.pending:before {
    font-size: 4.7em;
    top: calc(50% - 0.5em);
  }

  .misteries > div > div.resolved > div.level3:before {
    font-size: 2.5em;
    top: calc(50% - 0.5em);
  }
}