.Timer > div {
  align-content: center;
  align-items: stretch;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 329px;
}

.Timer > div > div{
  flex-wrap: wrap;
  width: 250px;
  font-size: 50px;
  line-height: 120%;
  font-family: 'VT323';
}
