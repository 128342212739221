@media (min-width: 800px) {
  /*.viewClue {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }*/
  .viewClue p.clueZone.hide,
  .viewClue p.clueZone.show {
    font-size: 3rem;
    width: calc(100% - 10px)
  }

}
.viewClue button{
  padding: 15px 30px;
  min-width: 250px;
}


.viewClue p.clueZone.hide,
.viewClue p.clueZone.show {
  margin-top: 10px;
  font-size: 2rem;
  text-align: center;
  transition: opacity 0.3s;
}

.viewClue p.clueZone.hide {
  display: none;
  opacity: 0;
}

.viewClue p.clueZone.show {
  display: block;
  opacity: 1;
}