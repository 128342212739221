body {
  
}

nav {
  text-align: right;
  padding: 10px 10px 35px 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  z-index: 20;
  color: #fff;
  background-color: var(--purple-color);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 80% 100%, calc(80% - 20px) calc(100% - 20px), calc(55% + 20px) calc(100% - 20px), 55% 100%, 0% 100%);
}

nav a {
  color: #fff;
}

section.cyberpunk.rules:after  {
  background-color: var(--purple-color);
}

footer {
  background-color: var(--purple-color);
}