.Login {
  padding-top: 45px;
}

.Login div.loginMessage {
  font-size: 2em;
  line-height: 120%;
}

.Login div.loginMessage.hide {
  display: none;
  opacity: 0;
}

.Login div.loginMessage.show {
  display: block;
  opacity: 1;
}

.Login label {
  margin-bottom: -26px;
  padding-left: 28px;
  color: #000;
  position: relative;
  display: block;
  z-index: 10;
}